/* eslint-disable @typescript-eslint/ban-ts-comment,unicorn/consistent-function-scoping */
import { ChangeEvent, DragEvent, useId, useRef, useState } from 'react';

import cn from 'classnames';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

import { AUDIO_EXTENSIONS } from '../../../constants/audio-extensions';
import { MAX_SIZE } from '../../../constants/size';
import { formatBytes } from '../../../helpers/file';
import { showAlert } from '../../../helpers/show-toast';
import { CustomIcon } from '../../custom-icon/custom-icon';
import { InputError } from '../common/input-error/input-error';
import { InputLabel } from '../common/input-label/input-label';

import audioImage from '../../../static/images/audio.png';
import fileImage from '../../../static/images/png-file-icon-6.jpg';

interface AvatarUploadProps {
  label: string;
  name: string;
  accept?: boolean;
  className?: HTMLDivElement['className'];
  extensionList?: string;
  imagePreview?: string;
  imagePreviewControl?: string | null;
  maxSize?: number;
  removePreview?: () => void;
  rules?: RegisterOptions;
  updateImagePreviewControls?: (value: string | null) => void;
}

export const FileUpload = ({
  label,
  rules,
  name,
  maxSize,
  extensionList,
  imagePreview,
  removePreview,
  imagePreviewControl,
  updateImagePreviewControls,
  className,
  accept = true,
}: AvatarUploadProps) => {
  const [image, setImage] = useState<string | null | undefined>(imagePreview);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const uploadRef = useRef<HTMLLabelElement>(null);
  const uploadTextRef = useRef<HTMLDivElement>(null);

  const { control, setValue } = useFormContext();
  const id = useId();
  const isAudio = !!extensionList?.includes('MP3');

  const currentImage = imagePreviewControl || image;

  const updateCurrentImage = (value: string | null) => {
    if (updateImagePreviewControls) {
      updateImagePreviewControls(value);
    } else {
      setImage(value);
    }
  };

  const deletePreview = () => {
    updateCurrentImage(null);
    setValue(name, null);
    removePreview?.();
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange } }) => {
        const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
          if (!e.target.files) return;

          if (isAudio && !AUDIO_EXTENSIONS[e.target.files?.[0]?.type]) {
            showAlert({
              message: 'Несоответствующий формат файла',
              type: 'warning',
            });

            return;
          }

          const max = maxSize || MAX_SIZE.file;

          if (e.target.files?.[0]?.size > max) {
            showAlert({
              message: `Максимальный размер файла не более ${formatBytes(max)}`,
              type: 'error',
            });

            return;
          }

          const file = e.target.files[0];

          if (file) {
            onChange(file);
            buttonRef.current?.classList.remove('hidden');

            if (AUDIO_EXTENSIONS[file.type]) {
              updateCurrentImage(audioImage);

              return;
            }
            updateCurrentImage(file.type.includes('image/') ? URL.createObjectURL(file) : fileImage);
          }
        };

        const resetImage = () => {
          updateCurrentImage(null);

          buttonRef.current?.classList.add('hidden');
          onChange(null);
          if (inputRef.current) {
            inputRef.current.value = '';
          }
        };

        const onDragStartHandler = (e: DragEvent<HTMLDivElement>) => {
          e.preventDefault();
        };

        const onLeaveHandler = (e: DragEvent<HTMLDivElement>) => {
          e.preventDefault();
          if (uploadTextRef.current && uploadRef.current) {
            uploadRef.current.classList.remove('!opacity-50');
            uploadTextRef.current.classList.add('opacity-0');
            uploadTextRef.current.classList.add('translate-y-1');
          }
        };

        const onDragOverHandler = (e: DragEvent<HTMLDivElement>) => {
          e.preventDefault();
          if (uploadTextRef.current && uploadRef.current && !uploadRef.current.className.includes('!opacity-50')) {
            uploadRef.current.classList.add('!opacity-50');
            uploadTextRef.current.classList.remove('opacity-0');
            uploadTextRef.current.classList.remove('translate-y-1');
          }
        };

        const onDropHandler = (e: DragEvent<HTMLDivElement>) => {
          e.preventDefault();

          const file = e.dataTransfer.files[0];

          if (isAudio && !AUDIO_EXTENSIONS[file?.type]) {
            showAlert({
              message: 'Несоответствующий формат файла',
              type: 'warning',
            });

            return;
          }

          const max = maxSize || MAX_SIZE.file;

          if (file?.size > max) {
            showAlert({
              message: `Максимальный размер файла не более ${formatBytes(max)}`,
              type: 'error',
            });

            return;
          }

          if (file) {
            if (uploadRef.current && uploadTextRef.current) {
              uploadRef.current.classList.remove('!opacity-50');
              uploadTextRef.current.classList.add('opacity-0');
              uploadTextRef.current.classList.add('translate-y-1');
            }
            onChange(file);
            if (AUDIO_EXTENSIONS[file.type]) {
              updateCurrentImage(audioImage);
              buttonRef.current?.classList.remove('hidden');

              return;
            }
            updateCurrentImage(file.type.includes('image/') ? URL.createObjectURL(file) : fileImage);
            buttonRef.current?.classList.remove('hidden');
          }
        };

        return (
          <div
            className='space-y-1'
            onDragLeave={onLeaveHandler}
            onDragOver={onDragOverHandler}
            onDragStart={onDragStartHandler}
            onDrop={onDropHandler}
          >
            <InputLabel htmlFor={id}>{label}</InputLabel>

            <label
              ref={uploadRef}
              className={cn(
                'flex cursor-pointer select-none items-center gap-4 rounded-lg border border-input-border bg-bg/50 p-4 hover:border-input-border2',
                className
              )}
            >
              <input
                ref={inputRef}
                //@ts-ignore
                accept={accept && 'image/*'}
                className='hidden'
                id={id}
                onChange={handleImageChange}
                type='file'
              />
              <div className='relative z-10 h-16 w-16 rounded-full bg-white'>
                <img
                  alt=''
                  src={currentImage || ''}
                  className={cn('relative z-20 h-full w-full rounded-full object-cover', {
                    hidden: !currentImage?.length,
                  })}
                />

                <button
                  ref={buttonRef}
                  onClick={() => (imagePreview ? deletePreview() : resetImage())}
                  type='button'
                  className={cn(
                    'absolute top-0 right-0 z-30 flex hidden items-center justify-center rounded bg-red p-1 hover:opacity-75',
                    { '!flex': currentImage }
                  )}
                >
                  <CustomIcon className='h-2 w-2 fill-white' name='rr-cross' />
                </button>

                <CustomIcon
                  name={isAudio ? 'sr-music' : 'rr-camera'}
                  className={cn('absolute top-1/2 left-1/2 -z-10 h-7 w-7 -translate-y-1/2 -translate-x-1/2 fill-gray', {
                    hidden: currentImage?.length,
                  })}
                />
              </div>
              <p className='text-sm font-medium'>
                Допустимые расширения:
                <br />
                {extensionList || ` PNG, JPG, JPEG, GIF ${accept ? '' : ', DOC, PDF, PPT, TXT, XLS'}`}
              </p>
              <p
                ref={uploadTextRef}
                className='absolute left-[40%] translate-y-1 text-xs font-medium text-gray opacity-0 transition'
              >
                Отпустите что бы добавить файл
              </p>
            </label>

            <InputError name={name} />
          </div>
        );
      }}
    />
  );
};
